<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" v-if="showPage" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
      <el-button type="success" @click="downloadSp()">
        <i class="el-icon-download" />下载
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="categoryName"
          label="分类名称"
          width="150"
        ></el-table-column>
        <el-table-column prop="cover" label="封面照片" width="150">
          <template slot-scope="scope">
            <img :src="scope.row.cover" alt="" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="200">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.name }}</div>
            <div style="text-align: left; margin-top: 10px">
              <el-tag type="success" style="margin-right: 5px">{{
                scope.row.suppliers
              }}</el-tag>
              <el-tag>{{ scope.row.suppliersProductId }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="picture" label="商品照片" width="300">
          <template slot-scope="scope">
            <div style="display: flex">
              <img
                :src="x"
                v-for="x in scope.row.picture.split(';')"
                :key="x"
                alt=""
                style="width: 50px"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="商品信息" width="350">
          <template slot-scope="scope">
            <div
              class="line"
              v-for="x in scope.row.specificationList"
              :key="x.specificationId"
            >
              <span>{{ x.specification }}：</span>
              <em>￥{{ x.price }}，</em>
              库存：<i>{{ x.inventory || 0 }}件</i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="商品描述"
          width="220"
        ></el-table-column>
        <el-table-column prop="detailPicture" label="详情长图" width="300">
          <template slot-scope="scope">
            <div style="display: flex">
              <img
                :src="x"
                v-for="x in scope.row.detailPicture.split(';')"
                :key="x"
                alt=""
                style="width: 50px"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="highGrade" label="优质商品" width="80">
          <template slot-scope="scope">{{
            scope.row.highGrade === "Y" ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column prop="isBy" label="是否包邮" width="80">
          <template slot-scope="scope">{{
            scope.row.isBy === "1" ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否上架" width="80">
          <template slot-scope="scope">{{
            scope.row.isShow ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column prop label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="upOrDown(scope.row)">{{
              scope.row.isShow ? "下架" : "上架"
            }}</el-button>
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      showPage: false,
      page: {
        page: 1,
        size: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-select",
          fieldName: "categoryId",
          data: [],
          attrs: {
            placeholder: "商品分类",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "name",
          attrs: {
            placeholder: "商品名称",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "highGrade",
          data: [
            {
              label: "是否优质",
              value: "",
            },
            {
              label: "是",
              value: "Y",
            },
            {
              label: "否",
              value: "N",
            },
          ],
          attrs: {
            placeholder: "是否优质",
          },
        },
      ],
    };
  },
  methods: {
    upOrDown(opt) {
      //上下架
      this.getAjax(
        "api/admin/product/listing",
        {
          isShow: !opt.isShow,
          productId: opt.id,
        },
        "post"
      ).then((res) => {
        this.$message.success("操作成功");
        this.initPage();
      });
    },
    downloadSp() {
      //下载商品
      this.getAjax("api/admin/product/down", {}, "get").then((res) => {
        console.log(res);
      });
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.size;
    },
    handleSizeChange(size) {
      this.page.size = size;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/product/list",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        res.data.records.forEach((e) => {
          e.specificationList.forEach((o) => {
            o.price = o.price / 100;
          });
        });
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.initDialog();
    },
    editData(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/admin/product/del", { ids: [item.id] }, "post").then(
          (res) => {
            this.$message.success("删除成功");
            this.initPage();
          }
        );
      });
    },
  },
  mounted() {
    this.getAjax(
      "api/admin/productCategory/list",
      {
        page: 1,
        size: 10,
      },
      "post"
    ).then((res) => {
      this.formItems[0].data = res.data.records.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      this.showPage = true;
      this.initPage();
    });
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .leftBox {
    width: 220px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;
    .line {
      display: flex;
      width: 300px;
      em {
        color: #f00;
      }
      i {
        font-style: normal;
        color: #67c23a;
      }
    }
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
